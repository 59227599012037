module.exports = [{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":650,"linkImagesToOriginal":true,"showCaptions":false,"markdownCaptions":false,"backgroundColor":"white","quality":50,"withWebp":false,"withAvif":false,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".mdx",".md"],"gatsbyRemarkPlugins":[{"resolve":"/Users/volna/Documents/Web-site/VOLNA/node_modules/gatsby-remark-custom-blocks","id":"1231a212-1b21-5a33-92cb-da66922030fa","name":"gatsby-remark-custom-blocks","version":"4.25.0","modulePath":"/Users/volna/Documents/Web-site/VOLNA/node_modules/gatsby-remark-custom-blocks/index.js","module":{},"pluginOptions":{"plugins":[],"blocks":{"image":{"classes":"inline-image"},"video":{"classes":"inline-video"}}},"nodeAPIs":[],"browserAPIs":[],"ssrAPIs":[]},{"resolve":"/Users/volna/Documents/Web-site/VOLNA/node_modules/gatsby-remark-images","id":"8f16df2a-5100-597c-b0a8-9fc73336dd34","name":"gatsby-remark-images","version":"6.25.0","modulePath":"/Users/volna/Documents/Web-site/VOLNA/node_modules/gatsby-remark-images/index.js","pluginOptions":{"plugins":[],"maxWidth":3840,"linkImagesToOriginal":false,"quality":90,"loading":"lazy","srcSetBreakpoints":[750,1080,1366,1920,3840]},"nodeAPIs":["pluginOptionsSchema"],"browserAPIs":["onRouteUpdate"],"ssrAPIs":[]}],"defaultLayouts":{},"lessBabel":false,"remarkPlugins":[],"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/Users/volna/Documents/Web-site/VOLNA","commonmark":false,"JSFrontmatterEngine":false,"engines":{}},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":3840,"linkImagesToOriginal":false,"quality":90,"loading":"lazy","srcSetBreakpoints":[750,1080,1366,1920,3840]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
