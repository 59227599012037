import React from 'react'
import TrackVisibility from 'react-on-screen';

export default function VimeoPlayer({videoID, showControls}) {

    const controls = ['?background=1', '']

    const ComponentToTrack = ({ isVisible }) => {
        const style = {visibility: isVisible ? 'visible' : 'hidden'};
        return <iframe
        style = {style}
        title= { videoID }
        loading = "lazy"
        src={ "https://player.vimeo.com/video/" + videoID + controls[showControls] } 
        frameBorder="0" 
        allowFullScreen>
        </iframe>
    }
      
    return (
        <TrackVisibility partialVisibility offset={1080} throttleInterval={400}>
            <ComponentToTrack />
        </TrackVisibility>
    )
}